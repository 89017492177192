import { loadStripe } from "@stripe/stripe-js";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Flex,
  Form,
  Grid,
  Input,
  message,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import { Picker } from "antd-mobile";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import Icon, {
  ClockCircleOutlined,
  DollarCircleOutlined,
  EditOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

import AddressInput from "./AddressInput";
import { GetBasicAuth, IsEmployeeUser, GetLogInState } from "../utils/Auth";
import { useQuery } from "../hooks/useQuery";
import { NotifyError } from "../utils/NotifyError";
import { RESTAURANT_IDS, TRestaurantSlug } from "../constants/o2";
import {
  RestaurantContext,
  RestaurantFeatureFlagsContext,
} from "../layouts/Page";
import RestaurantSVG from "../components/RestaurantSVG";
import { dateFmt, timeFmt } from "../utils/Time";
import TipBox from "./TipBox";
import {
  ComboChoices,
  CreateOrderStatus,
  CreateOrderResponse,
  CustomerInfo,
  GetWaitEstimateResponse,
  Tip,
  GroupMenuInfo,
} from "../types/api";
import { Address, FulfillmentType } from "../types/model";
import {
  IsPaidLineItem,
  IsRedeemedLineItem,
  PaidLineItem,
  RedeemedLineItem,
  LineItem,
  CartScope,
} from "../types/ui";
import {
  API_URL,
  convertPrice,
  fetchT,
  getFetch,
  getHourAndMinute,
  assertNever,
} from "../utils/Util";
import PhoneNumberInput from "./PhoneNumberInput";
import { useLargeVolumeDeliveryZoneInfo } from "../hooks/useLargeVolumeDeliveryZoneInfo";
import { useLargeVolumePickupConfig } from "../hooks/useLargeVolumePickupConfig";
import { LARGE_VOLUME_MIN_LEAD_DAYS } from "../constants/constants";
import { CartLineItemList } from "./CartLineItemList";
import { useCartStore } from "../stores/CartStore";
import dayjs, { Dayjs } from "dayjs";

const { useBreakpoint } = Grid;

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

// Maximum number of days into the future when an order can be placed.
const MAX_ORDER_DAY_IN_FUTURE = 30;

type CartProps = {
  // Set if the customer is logged in.
  customer: CustomerInfo | null;

  fulfillmentType: FulfillmentType;
  groupMenu?: GroupMenuInfo;

  cartScope: CartScope;
  subtotal: number;
  showCart: boolean;
  hideCart: () => void;
};

// Make sure to call `loadStripe` outside of a render to avoid
// recreating the `Stripe` object on every render.
const DEFAULT_STRIPE_PROMISE = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "",
);
const stripePromises: Record<TRestaurantSlug, ReturnType<typeof loadStripe>> = {
  cupertino: DEFAULT_STRIPE_PROMISE,
  // For test we only use one stripe org
  "palo-alto":
    process.env.NODE_ENV === "development"
      ? DEFAULT_STRIPE_PROMISE
      : loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_PALO_ALTO ?? ""),
};

function countItems(cart: Map<string, LineItem>): number {
  let total = 0;
  cart.forEach((lineItem) => {
    total += lineItem.quantity;
  });
  return total;
}

function DrawerCart(props: CartProps & { width: string }) {
  const screens = useBreakpoint();
  const {
    customer,
    fulfillmentType,
    groupMenu,
    cartScope,
    subtotal,
    showCart,
    hideCart,
    width,
  } = props;

  const { restaurantSlug } = useParams<{
    restaurantSlug: TRestaurantSlug;
  }>();
  const navigate = useNavigate();
  const query = useQuery();

  const cart = useCartStore((state) => state.getCartByScope(cartScope));
  const allLineItems = useMemo(
    () => new Map(Object.entries(cart.lineItems)),
    [cart.lineItems],
  );
  const lineItemCount = countItems(allLineItems);
  const restaurant = useContext(RestaurantContext);
  const restaurantFeatureFlags = useContext(RestaurantFeatureFlagsContext);

  const enableTip = !(
    restaurantFeatureFlags?.isEnabled("UseSquareCheckout") ?? false
  );

  // Set default open hours to 11am-9pm so users can still place orders if we
  // fail to fetch restaurant info. The backend also performs open hour check.
  const [openHour, openMinute] = getHourAndMinute(
    groupMenu?.fulfillment_time_start_second ??
      restaurant?.open_second ??
      11 * 3600,
  );
  const [closeHour, closeMinute] = getHourAndMinute(
    groupMenu?.fulfillment_time_end_second ??
      restaurant?.close_second ??
      21 * 3600,
  );
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  let minOrderDate = dayjs().startOf("day");
  if (groupMenu?.fulfillment_start_date != null) {
    minOrderDate = dayjs(groupMenu?.fulfillment_start_date);
  }

  let maxOrderDate = dayjs()
    .startOf("day")
    .add(MAX_ORDER_DAY_IN_FUTURE - 1, "days");
  if (groupMenu?.fulfillment_end_date != null) {
    maxOrderDate = dayjs(groupMenu.fulfillment_end_date);
  }

  // If not null, means we're doing a table side order.
  const tableID: string | null = query.get("table_id");

  // Skip email field if:
  // - the customer is logged in with phone number
  // - the customer is only redeeming things
  // - the customer is an employee making a post-pay/phone order
  //
  // This handles the case where a customer who doesn't have email (e.g.
  // elderly) wants to purely redeem something.
  const [requireEmail, setRequireEmail] = useState<boolean>(true);
  const [hideEmail, setHideEmail] = useState<boolean>(false);
  // TODO: there is a re rendering cycle going on with this useEffect. I removed the allLineItems for now, but this needs to be resolved
  //requiredEmail & hideEmail -> the form -> sessionstorage -> cart -> allLineItems -> requiredEmail & hideEmail
  useEffect(() => {
    if (allRedeemed(allLineItems) && customer && !customer.email) {
      setRequireEmail(false);
      setHideEmail(true);
    } else if (IsEmployeeUser() || tableID !== null) {
      setRequireEmail(false);
      setHideEmail(true);
    } else {
      setRequireEmail(true);
      setHideEmail(false);
    }
  }, [customer, tableID]);

  const [checkoutForm] = Form.useForm();

  // Current estimated wait time in minutes.
  // Users may not submit order that is due before this wait period in order to
  // space out orders during peak demand. The backed only enforces a minimum 15
  // minute wait in order to avoid user confusion when there's a race between UI
  // and backend when fetching estimate (no one is expected to use the API
  // directly anyways).
  // TODO: don't hardcode min wait minutes
  const [waitEstimateMin, setWaitEstimateMin] = useState<number>(15);
  const updateWaitEstimate = (itemCount: number) => {
    if (restaurantSlug) {
      getFetch<GetWaitEstimateResponse>(
        `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug]}/wait_estimate?item_count=${itemCount}`,
        (res) => {
          if (res) {
            setWaitEstimateMin(res.seconds / 60);
          }
        },
      );
    }
  };
  useEffect(() => {
    updateWaitEstimate(lineItemCount);
  }, [lineItemCount]);

  // Calculate earliest possible pickup time.
  const getMinPickupTime = () => {
    const now = dayjs();
    const year: number = now.year();
    const month: number = now.month();
    const day: number = now.date();

    const todayOpen = dayjs()
      .set("year", year)
      .set("month", month)
      .set("date", day)
      .set("hour", openHour)
      .set("minute", openMinute);

    const todayClose = dayjs()
      .set("year", year)
      .set("month", month)
      .set("date", day)
      .set("hour", closeHour)
      .set("minute", closeMinute);

    if (
      fulfillmentType === "LARGE_VOLUME_DELIVERY" ||
      fulfillmentType === "LARGE_VOLUME_PICKUP"
    ) {
      return now.add(LARGE_VOLUME_MIN_LEAD_DAYS, "day");
    }

    // Get earliest possible time to start working on orders based on restaurant
    // hours.
    let start = dayjs(now);
    if (todayOpen.isSame(todayClose) || todayClose.isBefore(todayOpen)) {
      // Do nothing, the restaurant open hours are not set or are invalid.
    } else if (start.isAfter(todayClose)) {
      start = dayjs(todayOpen).add(1, "day");
    } else if (start.isBefore(todayOpen)) {
      start = dayjs(todayOpen);
    }

    if (groupMenu?.fulfillment_start_date != null) {
      const earliestFulfillment = dayjs(groupMenu?.fulfillment_start_date)
        .add(openHour, "hours")
        .add(openMinute, "minutes");
      if (start.isBefore(earliestFulfillment)) {
        start = earliestFulfillment;
      }
    }

    if (groupMenu?.fulfillment_time_start_second != null) {
      // No need to add buffer if we have specified fulfillment time.
      return start;
    }

    const minPickup = start.add(waitEstimateMin, "minute");

    // Default to 2 minutes more than the min pick up time in order to give
    // customers time to fill out the checkout fields.
    if (minPickup.diff(now, "minute") > waitEstimateMin + 2) {
      return minPickup;
    }
    return minPickup.add(2, "minute");
  };

  useEffect(() => {
    // Update pickup time in the form if it's before the new pickup limit.
    // Also update it if the page has changed to a different fulfillment type.
    const newMinPickupTime = getMinPickupTime();
    const currPickupTime = checkoutForm.getFieldValue("pickup_time");
    if (newMinPickupTime.isAfter(currPickupTime)) {
      checkoutForm.setFieldsValue({ pickup_time: newMinPickupTime });
    }
  }, [waitEstimateMin, restaurant, props.fulfillmentType, props.groupMenu]);

  const minPickupTime = getMinPickupTime();
  const initialValues = {
    utensils:
      fulfillmentType === "LARGE_VOLUME_DELIVERY" ||
      fulfillmentType === "LARGE_VOLUME_PICKUP",
    notes: "",
    pickup_time: minPickupTime,
    customer_name:
      !IsEmployeeUser() && customer && customer.name ? customer.name : "",
    customer_email:
      !IsEmployeeUser() && customer && customer.email ? customer.email : "",
    customer_phone:
      !IsEmployeeUser() && customer && customer.phone ? customer.phone : "",
    customer_address: { line1: "", state: "", city: "", postal_code: "" },
  };
  const storedUtensils = sessionStorage?.getItem(
    `${fulfillmentType}:cart_utensils`,
  );
  if (storedUtensils && storedUtensils === "true") {
    initialValues.utensils = true;
  }
  const storedNotes = sessionStorage?.getItem(`${fulfillmentType}:cart_notes`);
  if (storedNotes) {
    initialValues.notes = storedNotes;
  }

  const storedPickupTime = sessionStorage?.getItem(
    `${fulfillmentType}:cart_pickup_time`,
  );
  if (storedPickupTime) {
    const storedDayjs = dayjs(storedPickupTime);
    if (storedDayjs.isAfter(minPickupTime)) {
      initialValues.pickup_time = storedDayjs;
    }
  }

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    initialValues.pickup_time,
  );
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
    initialValues.pickup_time,
  );

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    updatePickupTime(date, selectedTime);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
    updatePickupTime(selectedDate, time);
  };

  const updatePickupTime = (date: Dayjs | null, time: Dayjs | null) => {
    if (date && time) {
      const combinedDateTime = date
        .hour(time.hour())
        .minute(time.minute())
        .second(0);
      checkoutForm.setFieldsValue({ pickup_time: combinedDateTime });
      sessionStorage?.setItem(
        `${fulfillmentType}:cart_pickup_time`,
        combinedDateTime.toISOString(),
      );
      checkoutForm.validateFields(["pickup_time"]);
    }
  };

  const storedCustomerName = sessionStorage?.getItem(
    `${fulfillmentType}:cart_customer_name`,
  );
  if (storedCustomerName) {
    initialValues.customer_name = storedCustomerName;
  }
  const storedCustomerEmail = sessionStorage?.getItem(
    `${fulfillmentType}:cart_customer_email`,
  );
  if (storedCustomerEmail) {
    initialValues.customer_email = storedCustomerEmail;
  }
  const storedCustomerPhone = sessionStorage?.getItem(
    `${fulfillmentType}:cart_customer_phone`,
  );
  if (storedCustomerPhone) {
    initialValues.customer_phone = storedCustomerPhone;
  }
  const storedCustomerAddress = sessionStorage?.getItem(
    `${fulfillmentType}:cart_customer_address`,
  );
  if (storedCustomerAddress) {
    initialValues.customer_address = JSON.parse(storedCustomerAddress);
  }

  const [tip, setTip] = React.useState<Tip>({});
  const [confirmLocation, setConfirmLocation] = React.useState<boolean>(false);

  // For checking delivery order minimum spend.
  const [address, setAddress] = React.useState<Address | null>(null);

  const zoneInfo = useLargeVolumeDeliveryZoneInfo({
    restaurantSlug: restaurantSlug!,
  });
  const pickupConfig = useLargeVolumePickupConfig({
    restaurantSlug: restaurantSlug!,
  });

  // null means no delivery fee, 0 means FREE delivery.
  let deliveryFee: number | null = null;
  let disableCheckoutMessage: string | null = null;
  switch (fulfillmentType) {
    case "IN_STORE":
    case "SHIP":
    case "GROUP":
    case "CAMPAIGN_BATCH_DELIVERY":
      // Do nothing
      break;
    case "LARGE_VOLUME_DELIVERY":
      if (address == null || address.postal_code == "") {
        disableCheckoutMessage =
          "Please provide your postal code for delivery orders.";
      } else if (zoneInfo == null) {
        disableCheckoutMessage =
          "Unable to load delivery postal code information.";
      } else {
        const zone = zoneInfo.zones_by_postal_code[address.postal_code];
        if (zone == null) {
          disableCheckoutMessage = `Sorry, we cannot deliver to ${address.postal_code}`;
        } else if (subtotal < zone.minimum_pretax_price) {
          disableCheckoutMessage = `Minimum order amount for group order delivery to ${
            address.postal_code
          } is $${convertPrice(zone.minimum_pretax_price, {
            dropZero: true,
          })}`;
        }

        if (zone != null) {
          if (subtotal >= zone.free_delivery_threshold) {
            deliveryFee = 0;
          } else {
            deliveryFee = zone.delivery_fee;
          }
        }
      }
      break;
    case "LARGE_VOLUME_PICKUP":
      if (pickupConfig == null) {
        disableCheckoutMessage =
          "Unable to load group order pickup information.";
      } else if (subtotal < pickupConfig.minimum_pretax_price) {
        disableCheckoutMessage = `Minimum order amount for group order pickup is $${convertPrice(
          pickupConfig.minimum_pretax_price,
          { dropZero: true },
        )}`;
      }
      break;
    default:
      assertNever(fulfillmentType);
  }

  const onCheckout = (values: {
    utensils: boolean;
    notes: string;
    pickup_time: Dayjs;
    customer_name: string;
    customer_email: string;
    customer_phone: string;
    customer_address: Address;
  }) => {
    setCheckoutLoading(true);

    const reqBody = {
      line_items: Array.from(allLineItems.values()).map((lineItem) => {
        const result = {
          item_schema_id: lineItem.item_schema.id,
          modifications: lineItem.modifications.map((mod) => mod.id),
          combo_choices: lineItem.combo_choices,
          notes: lineItem.notes,
          quantity: lineItem.quantity,
          redeem: false,
        };
        if (IsRedeemedLineItem(lineItem)) {
          result.redeem = true;
        }
        return result;
      }),
      include_utensils: values.utensils,
      notes: values.notes,
      estimated_pickup_time:
        IsEmployeeUser() || tableID !== null
          ? null
          : values.pickup_time.toISOString(),
      customer_name: values.customer_name,
      customer_email: values.customer_email,
      customer_phone: values.customer_phone,
      tip: tip,
      table_id: tableID,
      fulfillment_type: fulfillmentType,
      group_menu_id: groupMenu?.id,
      customer_address: values.customer_address,

      // Temporary: used by the client to indicate that it's up to date and can
      // support square checkout.
      temp_support_square: 1,
    };

    const auth = GetBasicAuth();
    let url =
      fulfillmentType === "SHIP"
        ? `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug!]}/ship/orders`
        : `${API_URL}/v1/public/r/${RESTAURANT_IDS[restaurantSlug!]}/create_order`;
    if (GetLogInState() === "LOGGED_IN") {
      url =
        fulfillmentType === "SHIP"
          ? `${API_URL}/v1/customer/r/${RESTAURANT_IDS[restaurantSlug!]}/ship/orders`
          : `${API_URL}/v1/customer/r/${RESTAURANT_IDS[restaurantSlug!]}/orders`;
    }

    // Call backend to create the Checkout Session
    fetchT<CreateOrderResponse>({
      method: "POST",
      url: url,
      body: reqBody,
      auth: auth ? auth : undefined,

      handleResponse: async (response: CreateOrderResponse) => {
        let orderStatus: CreateOrderStatus = "PAYMENT_REQUIRED";
        if (response.status == null) {
          // legacy behavior: assume present of stripe session id as indicating
          // payment is required.
          if (!response.stripe_session_id) {
            orderStatus = "REDEEMED";
          }
        } else {
          orderStatus = response.status;
        }

        switch (orderStatus) {
          case "PAYMENT_REQUIRED":
            if (response.stripe_session_id != null) {
              try {
                // Get Stripe.js instance
                const stripe = await stripePromises[restaurantSlug!];

                // When the customer clicks on the button, redirect them to Checkout.
                const result = await stripe!.redirectToCheckout({
                  sessionId: response.stripe_session_id,
                });

                if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                  console.error(result.error.message);
                  message.error(`Failed to checkout: ${result.error.message}`);
                }
              } catch (err) {
                console.error(err);
                message.error(`Failed to checkout. Please try again!`);
              }
              // On success, customer is directed to stripe and will be redirected
              // from there upon completion of payment.
            } else if (response.payment_url != null) {
              window.location.href = response.payment_url;
              // On success, customer will be directed back to us from square.
            } else {
              NotifyError(
                "Sorry, we're temporarily unavailable.",
                new Error("missing stripe session"),
              );
            }
            break;
          case "PAYMENT_AT_PICKUP":
            navigate(`/checkout_success?order_id=${response.id}`);
            break;
          case "REDEEMED":
            // The is a pure redeem order. Redirect to checkout success page.
            navigate(`/checkout_success?order_id=${response.id}`);
            break;
          default:
            assertNever(orderStatus);
        }

        setCheckoutLoading(false);
      },

      handleError: (err: Error) => {
        setCheckoutLoading(false);
        NotifyError("Checkout Failed", err);
      },
    });
  };

  const [timePickerVisible, setTimePickerVisible] = useState(false);

  // Generate time columns based on openHour and closeHour
  const generateTimeColumns = () => {
    const hourOptions = [];
    const minuteOptions = [];

    for (let hour = openHour; hour <= closeHour; hour++) {
      hourOptions.push({ label: String(hour).padStart(2, "0"), value: hour });
    }

    for (let minute = 0; minute < 60; minute += 5) {
      minuteOptions.push({
        label: String(minute).padStart(2, "0"),
        value: minute,
      });
    }

    return [hourOptions, minuteOptions];
  };

  const hidePickupTime = IsEmployeeUser() || tableID !== null || fulfillmentType === "SHIP";

  const timeColumns = generateTimeColumns();

  const formElem = (
    <Form
      form={checkoutForm}
      labelAlign="left"
      layout="vertical"
      initialValues={initialValues}
      colon={false}
      onFinish={onCheckout}
      onValuesChange={(
        changedValues,
        allValues: { [name: string]: string | boolean | Dayjs | Address },
      ) => {
        updatePickupTime(selectedDate, selectedTime);
        // Store cart info in session storage
        Object.entries(allValues).forEach(
          ([name, value]: [string, string | boolean | Dayjs | Address]) => {
            if (typeof value === "object") {
              if ("toISOString" in value) {
                sessionStorage?.setItem(
                  `${fulfillmentType}:cart_${name}`,
                  value.toISOString(),
                );
              } else {
                sessionStorage?.setItem(
                  `${fulfillmentType}:cart_${name}`,
                  JSON.stringify(value),
                );
              }
            } else if (typeof value === "boolean") {
              sessionStorage?.setItem(
                `${fulfillmentType}:cart_${name}`,
                value.toString(),
              );
            } else {
              sessionStorage?.setItem(`${fulfillmentType}:cart_${name}`, value);
            }
          },
        );
      }}
      style={{ width: "100%" }}
    >
      <Form.Item
        label={
          <span>
            <Icon component={RestaurantSVG} />
            &nbsp;Utensils / 餐具
          </span>
        }
        layout="horizontal"
        name="utensils"
        valuePropName="checked"
        hidden={fulfillmentType === "SHIP"}
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <EditOutlined />
            &nbsp;Notes / 備註
          </span>
        }
        name="notes"
      >
        <TextArea
          rows={4}
          placeholder="Any special instructions"
          showCount
          maxLength={256}
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <ClockCircleOutlined />
            &nbsp;
            {fulfillmentType === "LARGE_VOLUME_DELIVERY"
              ? "Delivery Time / 外送時間"
              : "Pickup Time / 取餐時間"}
          </span>
        }
        hidden={hidePickupTime}
        layout="horizontal"
        name="pickup_time"
        rules={hidePickupTime ? [] : [
          {
            required: true,
            message: `Pickup time must be at least ${waitEstimateMin} minutes from now`,
            validator: (rule, value) => {
              return new Promise<void>((resolve, reject) => {
                if (value.isAfter(dayjs().add(waitEstimateMin, "minutes"))) {
                  resolve();
                } else {
                  reject("invalid pickup time");
                }
              });
            },
          },
          {
            required: true,
            message: `Pickup time cannot be more than ${MAX_ORDER_DAY_IN_FUTURE} days from now`,
            validator: (rule, value) => {
              return new Promise<void>((resolve, reject) => {
                const today = dayjs().startOf("day");
                const limit = today.add(MAX_ORDER_DAY_IN_FUTURE, "days");
                if (value.isBefore(limit)) {
                  resolve();
                } else if (groupMenu?.fulfillment_end_date != null) {
                  resolve();
                } else {
                  reject("invalid large volume end time");
                }
              });
            },
          },
          {
            required: true,
            message: `Pickup time must be within business hours`,
            validator: (rule, value) => {
              return new Promise<void>((resolve, reject) => {
                const selectedDate = value.startOf("day");
                const openTime = selectedDate.hour(openHour).minute(openMinute);
                const closeTime = selectedDate
                  .hour(closeHour)
                  .minute(closeMinute);
                if (value.isBetween(openTime, closeTime, null, "[]")) {
                  resolve();
                } else {
                  reject("Pickup time must be within business hours");
                }
              });
            },
          },
        ]}
      >
        <Flex
          align="center"
          justify="flex-start"
          gap={8}
          style={{ position: "relative" }}
        >
          <DatePicker
            allowClear={false}
            value={selectedDate}
            onChange={handleDateChange}
            minDate={minOrderDate}
            maxDate={maxOrderDate}
            format={dateFmt}
            hideDisabledOptions
            inputReadOnly
          />
          {screens.sm ? (
            <TimePicker
              allowClear={false}
              minuteStep={5}
              value={selectedTime}
              onChange={handleTimeChange}
              format={timeFmt}
              hideDisabledOptions
              showNow={false}
              needConfirm={false}
              disabledTime={() => ({
                disabledHours: () => {
                  const hours = [];
                  for (let i = 0; i < 24; i++) {
                    if (i < openHour || i >= closeHour) {
                      hours.push(i);
                    }
                  }
                  return hours;
                },
                disabledMinutes: (selectedHour) => {
                  if (selectedHour === openHour) {
                    return Array.from({ length: openMinute }, (_, i) => i);
                  }
                  if (selectedHour === closeHour) {
                    return Array.from(
                      { length: 60 - closeMinute },
                      (_, i) => i + closeMinute,
                    );
                  }
                  return [];
                },
              })}
              inputReadOnly
            />
          ) : (
            <>
              <Button
                onClick={() => setTimePickerVisible(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "4px 9px",
                  height: "36px",
                  borderColor: "#d9d9d9",
                }}
              >
                <span>
                  {selectedTime ? selectedTime.format("HH:mm") : "Select Time"}
                </span>
                <ClockCircleOutlined style={{ color: "#d9d9d9" }} />
              </Button>
              <Picker
                columns={timeColumns}
                visible={timePickerVisible}
                onClose={() => setTimePickerVisible(false)}
                confirmText="Confirm"
                cancelText="Cancel"
                onConfirm={(value: any[]) => {
                  const [selectedHour, selectedMinute] = value;
                  if (selectedHour !== null && selectedMinute !== null) {
                    const time = dayjs()
                      .hour(selectedHour)
                      .minute(selectedMinute);
                    handleTimeChange(time);
                  }
                }}
              />
            </>
          )}
        </Flex>
        <Typography.Text type="secondary">
          {fulfillmentType === "LARGE_VOLUME_PICKUP" ||
          fulfillmentType === "LARGE_VOLUME_DELIVERY"
            ? null
            : `Current wait time: ${waitEstimateMin} min`}
        </Typography.Text>
      </Form.Item>

      <Form.Item
        label={
          <span>
            <UserOutlined />
            &nbsp;Name / 名字
          </span>
        }
        name="customer_name"
        rules={[
          {
            required: true,
            message: "Name is required.",
          },
          {
            message: "Name cannot exceed 128 characters.",
            type: "string",
            max: 128,
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <MailOutlined />
            &nbsp;Email
          </span>
        }
        hidden={hideEmail}
        name="customer_email"
        rules={[
          {
            required: requireEmail,
            type: "email",
            message: "Email is required and must be a valid email.",
          },
          {
            // RFC 3696 with errata limits email to 254 characters
            message: "Email cannot exceed 256 characters.",
            type: "string",
            max: 256,
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <PhoneOutlined />
            &nbsp;Phone / 電話
          </span>
        }
        name="customer_phone"
        hidden={IsEmployeeUser() || tableID !== null}
        rules={
          !(IsEmployeeUser() || tableID !== null)
            ? [
                {
                  required: !(IsEmployeeUser() || tableID !== null),
                  message:
                    "Phone number is required and must be a valid phone number.",
                  validator: (rule, value) => {
                    return new Promise<void>((resolve, reject) => {
                      const parsedPhone = parsePhoneNumber(value, "US");
                      if (parsedPhone && parsedPhone.isPossible()) {
                        resolve();
                      } else {
                        reject("invalid phone number");
                      }
                    });
                  },
                },
                {
                  required: !(IsEmployeeUser() || tableID !== null),
                  message: "Phone number cannot exceed 64 characters.",
                  type: "string",
                  max: 64,
                },
              ]
            : []
        }
      >
        <PhoneNumberInput />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <HomeOutlined />
            &nbsp;Delivery Address / 送貨地址
          </span>
        }
        name="customer_address"
        hidden={
          fulfillmentType !== "LARGE_VOLUME_DELIVERY" &&
          fulfillmentType !== "SHIP"
        }
        rules={
          fulfillmentType === "LARGE_VOLUME_DELIVERY" ||
          fulfillmentType === "SHIP"
            ? [
                {
                  required: true,
                  validator: (rule, value) => {
                    return new Promise<void>((resolve, reject) => {
                      if (value.line1 === "") {
                        reject("Address is required");
                      } else if (value.city === "") {
                        reject("City is required");
                      } else if (value.state === "") {
                        reject("State is required");
                      } else if (value.postal_code === "") {
                        reject("ZIP Code is required");
                      } else if (value.postal_code?.length !== 5) {
                        reject("Invalid ZIP code");
                      }
                      resolve();
                    });
                  },
                },
              ]
            : []
        }
      >
        <AddressInput
          value={initialValues.customer_address}
          onChange={setAddress}
          caOnly={fulfillmentType === "LARGE_VOLUME_DELIVERY"}
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <DollarCircleOutlined />
            &nbsp;Tip / 小費
          </span>
        }
        name="tip_box"
        hidden={IsEmployeeUser() || !enableTip}
      >
        <TipBox
          defaultTipPercentages={restaurant?.default_tip_percentages}
          onChange={setTip}
        />
      </Form.Item>
      <Flex vertical align="flex-start" style={{ gap: 8, paddingBottom: 8 }}>
        <Divider style={{ margin: 0 }} />
        {countPointsInCart(allLineItems) > 0 && (
          <Typography.Text>
            Points Used: {countPointsInCart(allLineItems)}
          </Typography.Text>
        )}
        {subtotal > 0 && (
          <Title level={5}>
            Subtotal: ${convertPrice(withTip(subtotal, tip))}
          </Title>
        )}
        {deliveryFee != null && (
          <Title level={5}>
            Delivery Fee:{" "}
            {deliveryFee === 0 ? "FREE" : convertPrice(deliveryFee)}
          </Title>
        )}
      </Flex>

      <Flex
        vertical
        align="center"
        style={{
          border: "3px solid #DEC623",
          padding: 16,
          gap: 8,
          borderRadius: 8,
        }}
      >
        <Alert
          type="warning"
          message="Food at this location is processed in a kitchen that produces
							dishes with milk, wheat, soybean, fish, shellfish, tree nuts,
							peanuts, eggs, and egg products. If you have a food allergy,
							please inform our staff."
        />
        {disableCheckoutMessage !== null && (
          <Alert showIcon type="error" message={disableCheckoutMessage} />
        )}
        {
          // location is implied for groupMenu
          restaurant != null && (
            <Checkbox
              checked={confirmLocation}
              onChange={(e) => setConfirmLocation(e.target.checked)}
            >
              Please confirm that you are ordering from:{" "}
              {restaurant.display_name}
            </Checkbox>
          )
        }
        {!!groupMenu?.is_batch_delivery && (
          <Paragraph>Your order will be delivered to your event.</Paragraph>
        )}
        <Form.Item style={{ margin: 0, width: "100%" }}>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            loading={checkoutLoading}
            disabled={disableCheckoutMessage != null || !confirmLocation}
          >
            {allRedeemed(allLineItems) ? "Redeem / 兌換" : "Checkout / 結帳"}
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );

  // Display redeemed and paid items separately
  const paidItems: Map<string, PaidLineItem> = new Map<string, PaidLineItem>();
  const redeemedItems: Map<string, RedeemedLineItem> = new Map<
    string,
    RedeemedLineItem
  >();
  allLineItems.forEach((value: LineItem, key: string) => {
    if (IsPaidLineItem(value)) {
      paidItems.set(key, value);
    } else {
      redeemedItems.set(key, value);
    }
  });

  return (
    <Drawer
      title="Cart / 購物車"
      placement="right"
      onClose={() => {
        hideCart();
        document.body.style.overflow = "auto";
      }}
      open={showCart}
      width={width}
    >
      {allLineItems.size > 0 ? (
        <div>
          <CartLineItemList cartScope={cartScope} cart={cart} />
          {formElem}
        </div>
      ) : (
        <Empty description="No items" />
      )}
    </Drawer>
  );
}

function withTip(subtotal: number, tip: Tip): number {
  if (tip.percentage) {
    return subtotal + (subtotal * tip.percentage) / 100;
  } else if (tip.fixed_amount) {
    return subtotal + tip.fixed_amount;
  } else {
    return subtotal;
  }
}

function ComboChoicesList({ choices }: { choices: ComboChoices }) {
  const arr = Object.entries(choices);
  arr.sort((a, b) => -1 * (a[1].quantity - b[1].quantity));
  return (
    <>
      {arr.map(([id, choice]) => {
        if (choice.quantity > 0) {
          return (
            <p style={{ color: "gray", fontSize: "10px", margin: "0px" }}>
              {choice.quantity} x {choice.name}
            </p>
          );
        }
        return null;
      })}
    </>
  );
}

function priceOrPoint(lineItem: LineItem): string {
  if (IsPaidLineItem(lineItem)) {
    return convertPrice(lineItem.price);
  } else {
    return lineItem.points + " points";
  }
}

function allRedeemed(cart: Map<string, LineItem>): boolean {
  let result = true;
  cart.forEach((item) => {
    if (IsPaidLineItem(item)) {
      result = false;
      return;
    }
  });
  return result;
}

export function countPointsInCart(cart: Map<string, LineItem>): number {
  let total = 0;
  cart.forEach((item) => {
    if (IsRedeemedLineItem(item)) {
      total += item.quantity * item.points;
    }
  });
  return total;
}

export default (props: CartProps) => {
  const screens = useBreakpoint();
  return <DrawerCart {...props} width={screens.sm ? "65%" : "100%"} />;
};
